import { Routes, Route } from "react-router-dom";
import './App.css';
import { HomePage } from './pages/home/HomePage';
import NewsDetailPage from "./pages/noticeDetail/NewsDetailPage";
import LaPeliculaDelCampeon from "./pages/noticeDetail/LaPeliculaDelCampeon";
import AboutUsPage from "./pages/about us/AboutUsPage";
import HistoriaPage from "./pages/about us/HistoriaPage";
import ScrollToTop from "./utils/ScrollToTop";



function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route index path="/" element={<HomePage />} />
        <Route path="/nino-menechella" element={<NewsDetailPage/>}/>
        <Route path="/nuestra-historia" element={<AboutUsPage />}/>
        <Route path="/hermosa-aventura" element={<HistoriaPage />}/>
        <Route path="/pelicula-del-campeon" element={<LaPeliculaDelCampeon />}/>
      </Routes>
    </div>
  );
}

export default App;
