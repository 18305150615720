import React from 'react';
import { images } from '../constants';

const sponsorName = [
    {brand: 'MaxiPiso', logo: images.Sponsor2Logo, link:'/', key:'2'},
    {brand: 'Be Management', logo: images.Sponsor3Logo, link:'/', key:'3'},
  ]
  
  const SponsorLogo = ({brand,logo,link}) => {
    return (
      <a target="_blank" rel='noreferrer' href={link}>
        <img src={logo} alt={brand} className='h-[40px] xs:h-[50px] fill-white' />
      </a>
    );
  };
  
  
  const Sponsors = ({bg}) => {
    return (
      <section className={`flex justify-center items-center shrink-0 px-9 py-4 ${bg} w-full shadow-[1px_1px_10px_rgba(0,_0,_0,_0.5)] relative z-40`}>
          <div className='max-w-[1080px]'>
            <div className='flex flex-col xs:flex-row items-center justify-center gap-x-[70px] gap-y-[20px] flex-wrap'>
              {sponsorName.map((sponsor) =>(
                <SponsorLogo key={sponsor.key} logo={sponsor.logo} brand={sponsor.brand} link={sponsor.link}/>
              ))}
            </div>
          </div>
      </section>
    )
  }
  
  export default Sponsors
  