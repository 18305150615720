import React from 'react';
import MainLayout from '../../components/MainLayout';
import Hero from './container/Hero';
import SecNews from './container/SecNews';
import AboutUs from './container/AboutUs';
import Family from './container/Family';

export const HomePage = () => {
  return (
    <MainLayout>
      <Hero />
      <SecNews />
      <AboutUs />
      <Family />
    </MainLayout>
  )
}
