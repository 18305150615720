import React from 'react';
import MainLayout from '../../components/MainLayout';
// import BreadCrumbs from '../../components/BreadCrumbs';
// import SocialShareButtons from '../../components/SocialShareButtons';

// const bradCrumbsData = [
//   {name: "Inicio", link: '/'},
//   {name: "Noticias", link: '/noticia'},
//   {name: "Nino", link: '/noticia/1'},
// ];

const AboutUsPage = () => {
  return (
    <MainLayout>
        <section className='container mx-auto max-w-[1080px] flex flex-col px-5 pt-[120px] pb-[100px] lgs:flex-row lgs:gap-x-5 lgs:items-start'>
            <article className='flex flex-col items-center w-full'>
                <h1 className='text-[30px] sm:text-[40px] font-nexa-thin uppercase mt-3 leading-[30px] sm:leading-[45px] mb-2'>La Pelicula <b className='font-nexa-bold bg-gradient-slvc bg-clip-text text-transparent'>Del Campeon.</b></h1>
                <div className='rounded-box object-cover w-full md:max-h-[500px] md:max-w-[1000px] aspect-video'>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/9Lx5T4quevU?si=3D7Ex3-N21j3XR5j" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
                <p className='mt-4 font-inter'>Película basada en la obtención del primer titulo en primera división del club, correspondiente al torneo clausura 2019 de liga amateur platense de futbol.</p>
            </article>
        </section>
    </MainLayout>
  )
}

export default AboutUsPage