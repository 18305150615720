import React from 'react';
import { images } from '../../../constants';
import { Link } from 'react-router-dom';

const AboutUs = () => {
  return (
    <section id='nosotros' className='flex w-full justify-center items-center pt-[120px] pb-[60px]'>
        <div className='w-full justify-center max-w-[700px] sm:max-w-[1080px] mx-[5px] sm:mx-[24px]'>
            <h2 className='font-nexa-thin text-[40px] xs:text-[48px] uppercase text-center sm:text-left leading-none mb-5 mx-2'>Sobre <b className='font-nexa-bold font-normal'>Nosotros</b></h2>
            <div className='flex flex-col items-center sm:items-start mx-4 sm:flex-row w-auto sm:w-full gap-6'>
                <div className='gap-y-[11px] w-full xs:w-[90%] sm:max-w-[500px] sm:w-1/2'>
                    <img className='w-full mb-[5px] h-[250px] object-cover' src={images.Equipo3} alt="El Nido" />
                    <Link className='text-[12px] inline-block uppercase font-nexa-thin  text-white rounded-box bg-solid-bordo-dark shadow-button py-[2px] px-[10px]  focus:bg-solid-bordo transition ease-in-out hover:bg-solid-bordo duration-300' to="/hermosa-aventura"><b className='font-nexa-bold'>Esa Hermosa Aventura,</b> LLAMADA LA 2006</Link>
                </div>
                <div className='gap-y-[11px] w-full xs:w-[90%] sm:max-w-[500px] sm:w-1/2'>
                    <img className='w-full mb-[5px] h-[250px] object-cover' src={images.Nino1} alt="Nuestra Historia" />
                    <Link className='text-[12px] uppercase font-nexa-thin inline-block md:inline-block text-white rounded-box bg-solid-bordo-dark shadow-button py-[2px] px-[10px]  focus:bg-solid-bordo transition ease-in-out hover:bg-solid-bordo duration-300' to="/nino-menechella"><b className='font-nexa-bold'>"Nino Menechella":</b> una vida de película</Link>
                </div>
                <div className='gap-y-[11px] w-full xs:w-[90%] sm:max-w-[500px] sm:w-1/2'>
                    <img className='w-full mb-[5px] h-[250px] object-cover' src={images.La2019} alt="Nuestra Historia" />
                    <Link className='text-[12px] uppercase font-nexa-thin inline-block md:inline-block text-white rounded-box bg-solid-bordo-dark shadow-button py-[2px] px-[10px]  focus:bg-solid-bordo transition ease-in-out hover:bg-solid-bordo duration-300' to="/pelicula-del-campeon"><b className='font-nexa-bold'>La Pelicula Del Campeon</b>, Clausura 2019</Link>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AboutUs