import React from 'react';
import Sponsors from '../../../components/Sponsors';
import New from '../../../components/New';
import { FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";

const Hero = () => {
  return (
    <section id='inicio' className={`w-full h-screen bg-white bg-cover bg-center bg-fixed flex flex-col justify-between items-center shrink-0 `}>
        <div className='w-full h-full max-w-[1080px] flex flex-col justify-between items-center mb-6'>
            <div className='flex flex-col h-full sml:flex-row min-h-[300px] max-w-[1080px] w-full justify-between gap-9 sml:gap-0 sml:justify-between items-center pt-28 pb-9 lg:pb-[76px] px-3 xs:px-4'>
                <div className='flex h-auto sml:h-[300px] flex-col justify-between items-center xs:items-start mt-6 xs:mt-0'>
                    <div className='flex flex-col items-center xs:items-start gap-5 '>
                        <h1 className='font-nexa-bold px-1 pt-3 text-[35px] uppercase bg-gradient-slvc bg-clip-text text-transparent text-center xs:text-left sms:text-[55px] xs:text-[45px] md:text-[65px] font-normal leading-[25px] xs:leading-[40px] md:leading-[47px] block drop-shadow-text'>
                            San Lorenzo
                            <br/>
                            <b className='text-[26px] sms:text-[40px] xs:text-[33px] md:text-[47px]'>de Villa Castells</b>
                        </h1>
                        <a className='rounded-box bg-solid-bordo-dark shadow-button px-[12px] py-[6px] uppercase font-zing-sans text-[14px] xs:text-[16px] text-center text-white focus:bg-solid-bordo transition ease-in-out hover:bg-solid-bordo duration-300' href='#familia'>Formá parte de la familia</a>
                    </div>
                </div>
                <New />
            </div>
            <div className='flex w-full justify-center items-start'>
                <div className='flex items-start gap-[16px]'>
                    <a target="_blank" rel='noreferrer' className='bg-solid-blue w-[40px] h-[40px] text-white text-center shadow-button flex justify-center items-center text-[30px] rounded-box focus:bg-solid-light-blue hover:bg-solid-light-blue transition ease-in-out duration-300' href='https://www.instagram.com/slvc.oficial/'>
                        <FaInstagram />
                    </a>
                    <a target="_blank" rel='noreferrer' className='bg-solid-blue w-[40px] h-[40px] text-white text-center shadow-button flex justify-center items-center text-[30px] rounded-box focus:bg-solid-light-blue hover:bg-solid-light-blue transition ease-in-out duration-300' href='https://www.youtube.com/@slvc.oficial'>
                        <FaYoutube />
                    </a>
                    <a target="_blank" rel='noreferrer' className='bg-solid-blue w-[40px] h-[40px] text-white text-center shadow-button flex justify-center items-center text-[30px] rounded-box focus:bg-solid-light-blue hover:bg-solid-light-blue transition ease-in-out duration-300' href='https://www.tiktok.com/@cuervosdecastells'>
                        <FaTiktok />
                    </a>
                </div>
            </div>
        </div>
        <Sponsors bg={'bg-gradient-blue'}/>
    </section>
  );
};

export default Hero