import React from 'react';
import { Link } from 'react-router-dom'
import { images } from '../../constants';
import MainLayout from '../../components/MainLayout';
import SuggestedNews from './container/SuggestedNews';
// import BreadCrumbs from '../../components/BreadCrumbs';
// import SocialShareButtons from '../../components/SocialShareButtons';

// const bradCrumbsData = [
//   {name: "Inicio", link: '/'},
//   {name: "Noticias", link: '/noticia'},
//   {name: "Nino", link: '/noticia/1'},
// ];

const newsData = [
  {
    _id: "1",
    image: images.Postal,
    title: "La Historia de nuestro Club",
    createdAt: "2019-01-28T15:35:53.607+0000",
  },
  {
    _id: "2",
    image: images.Equipo1,
    title: "Torneo Regional Federal Amateur en El Nido",
    createdAt: "2024-01-28T15:35:53.607+0000",
    link: "/nosotros/hermosa-aventura"
  },
]

const NoticeDetailPage = () => {
  return (
    <MainLayout>
        <section className='container mx-auto max-w-[1080px] flex flex-col px-5 pt-[120px] pb-[100px] lgs:flex-row lgs:gap-x-5 lgs:items-start'>
          <article className='flex-1'>
            {/* <BreadCrumbs data={bradCrumbsData} /> */}
            <img 
              className='rounded-box object-cover w-full md:max-h-[500px] md:max-w-[600px]'
              src={images.Nino1} 
              alt="Nino"
            />
            <Link to="/noticia?category=selectedCategory" className='opacity-80 font-zing-sans text-[16px] text-black tracking-widest px-3 py-1 border border-black inline-block mt-4'>
              HISTORIA
            </Link>
            <h1 className='text-[20px] sm:text-[30px] font-nexa-thin uppercase mt-3 leading-[20px] sm:leading-[35px] mb-2'><b className='font-nexa-bold bg-gradient-slvc bg-clip-text text-transparent'>“Nino” Menechella:</b> una vida de película con 33 años en San Lorenzo de Villa Castells</h1>
            <div className='mt-4 mb-[50px] flex flex-col items-center'>
              <p className='leading-7 text-[15px] font-inter'>Miles de millones de seres humanos forman parte de la humanidad, pero sólo un puñado puede decirse que fundó una institución. <b>Pelegrino Menechella</b> fue uno de los que comenzaron el fenómeno el 10 de noviembre de 1990, fecha que certificó Personerías Jurídicas con un grato nombre, <b>Club San Lorenzo de Villa Castells.</b> <br />
              El “Nino” Menechella creó, dio vida, en la familia y la comunidad deportiva de la ciudad. Comenzamos con ésta nota y la seguimos en presencia, cuando se juegue la última fecha de mayores del campeonato, el sábado 16 de diciembre cuando la <b>Liga Amateur Platense</b> le brinde un homenaje póstumo, en la cancha de la calle 485 entre 5 y 6.
              Allí donde escribió los últimos 33 años de su vida y donde pese a la ausencia física solo queda la alegría, un rastro de su bohemia, de un dirigente de las entidades de bien público. <br /><br />

              «Nino» creció en Tolosa, en el barrio donde los ingleses que construyeron los ferrocarriles usaron como viviend. «Las Mil Casas». Allí fue a vivir su madre italiana <b>Giovanna Vecchiolla</b> con 23 años, tras la Segunda Guerra, y conoció a <b>Tomás Menechella</b> noviando 6 meses y embarcando en un matrimonio que se selló por iglesia en 1950 y que dio tres hijos; el primero hijo fue nuestro querido y recordado “Nino”. Estudió en la Escuela primaria 89 y en la década del sesenta se enloqueció por Estudiantes de La Plata, llevando para siempre la admiración por aquel DT que se llamó <b>Osvaldo Zubeldía</b>, el entrenador de aquel equipo. Tal vez pensando en esa filosofía del «imposible» se involucró un día en el <b>Club Social y Cultural Villa Rivera</b>, donde fue técnico y secretario, una agrupación de fútbol infantil de los campeonatos de LISFI, con la cancha chica en 11 y 520, cerca de donde él vivía, en 5 entre 521 y 522. Como allí no había cancha de once, tras la despedida de tantos pibes del campo chico, un buen día puso en acción a «Nino» con quien era su amigo y se convertirá en una dupla inseparable: <b>Alfredo Giommi</b>.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[500px] md:max-w-[600px]'
                src={images.Nino2} 
                alt="Nino y Alfredo"
              />
              <p className='leading-7 text-[15px] font-inter'>En cuatro meses emprolijaron un terreno que quedó listo para jugar, en cancha de siete y otro de once y que en los diarios anunciaba esta dirección: calle 10 y 505, terreno que pertenecía a Salud de la Provincia. Entraron a meter arcos y pelotazos en una zona que era entonces un descampado, desde 8 a 10, y de 505 a 508. con la vista a las vías del tren, a metros de la Estación Gonnet. Ahí jugaba San Lorenzo sus primeros partidos ante el asombro de los pibes que escuchaban el trepidar del tren sobre las vías, que a Nino lo llevaba a su viejo oficio de maquinista del ferrocarril. <br />
              “Nino buscaba pibes de un lado para otro, lo traía, pero no dirigía, esa no era su pasión, sino que haya pibes jugando. Nino era la unión de todos”, afirma <b>Roberto Cornell</b>, «Tito», hoy titular de la Asociación Ribereña de Arbitros (ARA), quien a su hijo Martín, clase 80, lo llevó de Villa Rivera a San Lorenzo.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[500px] md:max-w-[600px]'
                src={images.Nino3} 
                alt="Revista"
              />
              <p className='leading-7 text-[15px] font-inter'>Castells era un lugar que se poblaba de a poco y de allí se desprendían los primeros chicos, como el caso de Lucas Baratucci, actual DT de la Primera, que de niño formó en la 85, mientras su papá dirigía la 78. <br />
              San Lorenzo debutó en LISFI, liga donde jugó muy poco, hasta probar con LIFIPA “que era más completa”, recuerda Domingo Messina, el pelirrojo DT de la 88. Desde entonces, dicen que Nino empezó a ir menos al hipódromo, su otra pasión, tanto es así que al nacer su hijo Emiliano Tomás, el 12 de junio de 1992, lo pensó bautizar Vilmar Tomás en honor a un jockey uruguayo, el «Topo» Vilmar Sanguinetti. <br />
              Y el 21 de febrero de 1994 llegó a casa Juana Marina, en cuyo nombre también habría un código de los pura sangre de carrera: “El primer nombre fue por mi nona y Marina por la ex jocketa Marina Lezcano”, dice su amada hija.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[500px] md:max-w-[600px]'
                src={images.Nino4} 
                alt="Equipo"
              />
              <p className='leading-7 text-[15px] font-inter'><b>Cabe preguntarse por qué le pusieron San Lorenzo…</b><br />
              La respuesta es de Tomás: «Al fundarse el club un hombre les trajo un juego de camisetas de San Lorenzo de Almagro. Si le hubieran traído un juego de Gimnasia, sería Gimnasia y Esgrima de Villa Castells», a lo que Juana corrige: «¡Nooo, no creo que de Gimnasia!”.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[500px] md:max-w-[600px]'
                src={images.Nino5} 
                alt="Tomi Menechella"
              />
              <p className='leading-7 text-[15px] font-inter'>Coleccionaba revistas El Gráfico, Goles, las de la historia Pincha, y siempre jugaba con los hijos como si ellos fueran dos amigos más. «Nino» respetó la decisión de Tomy cuando quiso ser arquero. “El día que cumplí 6 años papá me sentó arriba de la mesa, me regaló unos botines Mitre, pantaloncito y una remera de arquero Reuch, que tenía una pared de ladrillos a la vista”, dice el hoy adulto que lleva el apellido con orgullo.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[600px]'
                src={images.Nino6} 
                alt="Tomi chiquito"
              />
              <p className='leading-7 text-[15px] font-inter'><b>Ya corrían los años 2000</b> y Juanita salió en el diario, cuando no estaba organizado el femenino ni mucho menos su categoría, en ningún lado. Esto generó un debate en los escritorios de LIFIPA. Finalmente, la dejaron participar entre los varones durante partidos amistosos. El autor de esta nota conoció a Nino en esa etapa, cuando integraba la mesa directiva de la Liga Infantil y ante costumbres arraigadas tan fuertemente a la sociedad, en cuestiones de género costaba despertar del letargo. <br />
              “¿Vos me sacaste la foto del diario? La tiene en un cuadrito él, está ahí”, cuenta Juana, quien sigue hablando como si papá estuviera. (Anochece, hace frío, ella sirve el té. Pasaron 33 años de la fundación del Club cuyos colores están en toda la casa, cuadros, banderines, camisetas. Juana ya dejó de jugar, es referí y ademas coordinó el fútbol femenino en La Plata FC, tal vez a la espera que en un futuro lo pueda hacer en San Lorenzo, que todavía no organizó la estructura para las mujeres).</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[600px]'
                src={images.Nino7} 
                alt="Juana Menechella"
              />
              <p className='leading-7 text-[15px] font-inter'><b>Alfredo Giommi</b> es el soñador que hoy preside a esta institución familiar, donde poco importan los rótulos. El amigo de toda la vida que, pese a la ausencia del alma gemela del fútbol, sigue inquieto, regando las plantas, acomodando un alambrado. <br />
              “Yo le digo tío”, dice Juana. “Es mi padrino”, cuenta Tomás.
              “La función de mi viejo fue acompañarlo a Alfredo”, asegura Tomás. “para mí es Alfredo y Nino. Siempre pongo a Alfredo por encima de mi viejo”. <br />
              “Papá solo no iba a poder, y Alfredo solo tampoco”, reflexiona Juana. <br />
              Los demás clubes tomaron cariño por ellos. El más experimentado de todos, <b>Gustavo Fracassi</b>, de For Ever, desató la sonrisa al compararlos con personajes de la célebre película de “El Zorro”: Giommi era don <b>“Diego de la Vega”</b> y entonces Menechella era <b>“Bernardo”</b>, según la interpretación del dirigente mondonguero.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[500px] md:max-w-[600px]'
                src={images.Nino8} 
                alt="Alfredo y Nino"
              />
              <p className='leading-7 text-[15px] font-inter'>Y al igual que en el filme, lucharon juntos para defender a su pueblo privado de ciertas libertades. Don Alfredo recuerda una lucha a capa y espada en el reclamo por las tierras que eran de Salud, que por decisiones «superiores» se las quitron. <br />
              Fue a mediados de 2003 cuando quedaron en la calle con los pibes, siendo habitantes de ramblas, hasta conseguir un terreno virgen en 25 y 514, frente a La Plata FC. Con la pala los vieron desmalezar 2,5 hectáreas.
              Pero alcanzar las metas y sostenerlas fue la consigna de la dupla heroica., que había instalado un sueño en la secreta intimidad de sus charlas. Mientras buscaban un espacio propio, ya hablaban de que el club, al que apodaron «El Cuervo», tenía que resurgir como en la leyenda del ave fénix: de sus propias cenizas. <br />
              «Se había encaprichado en llegar a jugar en la Liga Amateur Platense, a pesar de que los gastos eran más grandes”, recuerda Messina.
              Y en 2007, sin cancha, lograron la afiliación. <br />
              Los que venían de LIFIPA se ganaron la amistad de todos desde el vamos. “Nino es lo más puro que hay”, lo presentaba Alfredo, el presidente del club. Lo cierto es que a pesar de que la representación exigía la presencia de un dirigente, a Alfredo siempre lo acompañaba Nino. Un episodio de 2009, en una reunión de Comité Ejecutivo, nos devuelve la imagen de Menechella, a veces parado, otras, sentado, afuera, con un cigarrillo encendido. Se recuerda una anécdota, cuando chocó con un alto directivo que comandaba la reunión. Menechella se animó a cruzarlo con una frase. Y el dirigente apeló a una respuesta que por el eco del salón no se alcanzó a escuchar con nitidez en su última palabra. <br />
              “Acá opinan los presidentes, y no los heladeros”. Eso pareció escuchar Nino, que no se echó atrás. <br />
              “Doctor, ¡yo seré heladero, pero decente!”. <br />
              La confusión fue porque el término ladero en verdad se refería a «ladero» (de quien acompaña a alguien, en ese caso a Alfredo Giommi).</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino9} 
                alt="Alfredo y Nino"
              />
              <p className='leading-7 text-[15px] font-inter'>Durante 14 años fueron nómades, inquilinos, tal como si los de Villa Castells revivieran el mito del <b>San Lorenzo de Almagro</b> en los años ochenta y noventa. <br />
              “Los chicos nos preguntaban ‘¿Dónde jugamos?’, y les decíamos que no sabíamos… alquilamos en Porteño, Comunidad Rural…  Como paseamos por todos los clubes, tenemos buena relación con casi todos los dirigentes”, expresa Nino en un reportaje. <br />
              Sufrir y gozar los aplausos cuando lo sorprendieron con el regalo de cumpleaños 60, aquel 24 de mayo de 2012. “Tus alegrías, tus chinches, compañerismo y compromiso”, quedó impreso en la placa y el corazón.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino10} 
                alt="Homenage Nino"
              />
              <p className='leading-7 text-[15px] font-inter'><b>Lo mejor estaba por venir.</b> Mientras el primer equipo empezó a asentarse en la B, Nino (y Alfredo) iban y venían a los despachos del poder con la idea de alcanzar el predio, «para defender lo que era nuestro, por amor propio», coincidían. Se hizo eslogan <b>“la vuelta al barrio”</b>, con el rumor de que la cesión de tierras estaría en Villa Castells, en 485 entre 5 y 6, cerca del origen, y a pocas cuadras de ADIP. <br />
              A fines de año 2014 el torneo Clausura tendrá dos últimas fechas apasionantes. San Lorenzo no solo que ganó el clásico a AMEBS sino que los pasaría en la tabla, a una fecha del final: San Lorenzo 28, AMEBS 27, Alumni 25. El domingo <b>7 de diciembre de 2014</b> (hoy se cumplen 9 años) golearon a Tricolores 3 a 0 y obtienen la primera estrella. Imagen impactante: los jugadores lloraban adentro de la cancha mientras todavía se jugaba el partido.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino11} 
                alt="Festejo"
              />
              <p className='leading-7 text-[15px] font-inter'>¿Quién les iba a arrebatar ese sueño? Al domingo siguiente (14/12) definieron el primer ascenso a la A, en el estadio «Juan Carmelo Zerillo» de <b>Gimnasia</b>, ante <b>Porteño</b>, el ganador del Apertura. Fue otra victoria 3 a 1 con goles de <b>Leonel Lozano, Martín Baleani y Patricio Salto</b>, de penal. En el diario El Día se publicó una foto con la Platea techada luciendo llena de familia sanlorencista y un subtítulo que avizoraba que “en 2015 podría debutar en su nueva cancha”.
              Empezaron a intensificar esfuerzos. Nino hacía los cercos con hilos, sacaba la bosta de las vacas que pastaban por ahí. Fue un albañil, pintor, canchero, ¡alma y vida! <br />
              El 12 de marzo de 2016 se inauguró con el corte de cinta simbólica y la bienvenida ante jugadores, autoridades, prensa y público en general.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino12} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>Una vez en Primera, tuvieron una jornada que los hizo más famosos. El 9 de septiembre visitaron la cancha de Estrella de Berisso, que tuvo como titular y con la 11 a una figura mundial: <b>Juan Sebastián Verón</b> (ídolo de «Nino» y de multitudes) <br />
              Cuando la “Bruja” se disponía a firmar la planilla, «Nino» le tiró un chascarrillo al árbitro para que lo escuchase el capitán local: “<b>¿Le vas a pedir documentos a éste…?</b>”. En el tramo final del encuentro, cuando era empate, Verón le anotó el gol a San Lorenzo, en orsai, tocando la pelota debajo del arco tras un rebote en el travesaño. Fue la derrota menos dolorosa de la vida de «Nino» y otro poco para el técnico <b>Sergio Sánchez</b>, otro pincharrata al que «Nino» eligió para su club. “Yo era un pibe cuando conocí a Nino, porque soy el sobrino de <b>Cacho Paolozzi</b>, uno de la barra del barrio donde se crió, entre Tolosa y Ringuelet”, contó Sánchez, reconocido hombre de UTEDYC La Plata. <br />
              Volviendo al partido donde Estrella, una vivencia de Tomás Menechella, que esa tarde fue el 12 del equipo azulgrana. <br />
              —“Debo ser el arquero con más partidos en el banco en la historia”, se ríe hoy Tomy. <br />
              —“Papi te dijo que seas jugador”, se vuelve a reír Juana.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino13} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>El 2019 trajo un campeonato en Primera A, de la mano de Maximiliano Borgarelli, con quien «Nino» tuvo una suerte de cábala después de cada partido. Si ganaba San Lorenzo, le decía al DT: “Tenes una semana más”. Si empataban o perdían, lo miraba entre serio y jocoso: “Mirá que estás en la cuerda floja”. <br />
              “Me quedó marcada la relación de hermandad de Nino y Alfredo, que se eligieron para andar toda la vida hasta el último día de Nino. No han laburado por un resultado deportivo, sino por los pibes”, afirma Maxi, que este año dio un paso al costado tras clasificarlos para el Regional Amateur del Consejo Federal de AFA.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino14} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>La vida se empecina en cambiar, y un día Nino se separó de mutuo acuerdo con la madre de sus hijos. Y, sí, una de las cosas que fue inclinando fue el fútbol. Tras 14 años en su solitaria bohemia, una noche con amigos, en un baile, apostó a otra razón para vivir pleno, a pesar de la salud que en la tercera edad siempre trae complicaciones. <br />
              —»<b>¡Ey, ¿querés salir a bailar? Tengo los pies fríos, ¿vos, no…?”</b>, cabeceó Nino, y desde entonces Laura Araujo, oriunda de Brandsen, fue compañera, otra hincha de San Lorenzo de Villa Castells, y la casa de la calle 5 se poblaba de sonrisas con “los chicos”, los dos de sangre, una hija de corazón de su primer matrimonio que Nino reconoció como propia, más los dos nietos de <b>Laura.</b></p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino15} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>“San Lorenzo es como un hijo, y nació antes que mi primer hijo”, le dijo Nino a un periodisa de Pase a la Liga, en un homenaje por los 30 años del club, en plena pandemia. “¿Somos diferentes? Sí… a mí me gusta el escolazo, la joda y éste no juega, no fuma, esa es la única diferencia entre nosotros”, dijo, mirándolo a Alfredo. <br />
              Los hijos hoy llevan el perfume humanista de papá, un legado. “Luchó para tener todo lo que obtuvieron con el tío, no sé qué hubiera pasado con San Lorenzo si no fuera por ellos”, dice Juana. <br />
              “Cuando lo desalojaron un montón de gente se bajó del barco, pero persistieron y fueron para adelante, de lunes a viernes, sábado y domingo, carne y uña. Le decían matrimonio”, añade Tomás.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino16} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>En la cancha ya se prepara el homenaje póstumo a quien pasó por una vida deportiva de 33 años, con una infraestructura que habla de sacrificios y de placer por servir. <br />
              “Se fue con un club crecido y en crecimiento”, afirma Baratucci. “Para mí está presente, ¿sabes?”, dijo su amigo “Tito” Cornell, quien formó parte de la juventud, aquella barra que lo conoció de pelo largo, viendo la gente pasar por la avenida 520, en la esquina de 5, donde se separan y se dan la mano los de Tolosa y Ringuelet; <b>el bar de José</b>, el billar, una guitarra, la revista de las carreras. <br />
              “Era uno de los pocos Pinchas en una barra de todos triperos; eramos veintipico, ahora, cinco, muchos ya hicieron el viaje y todos los meses hacemos una juntada y está Tomy Menechella”, sí, el hijo de Nino, que a los amigos de su padre los llama <b>“tíos”</b>.</p>
              <img 
                className='rounded-box object-cover w-full my-4 md:max-h-[650px] md:max-w-[500px]'
                src={images.Nino17} 
                alt="Innaguracion"
              />
              <p className='leading-7 text-[15px] font-inter'>Con Alfredo Giommi y su mujer <b>Rosa Folino</b> también hay un espíritu familiar. <b>“Nuestra primera dama, Rosita”</b>, decía Nino. <br />
              En cada partido de San Lorenzo, en una práctica, cuando el sol alumbra el verde o cuando se apagan las luces y todo es penumbra y silencio, Nino siempre está. La lucha por la institución fue su victoria eterna mientras forjaba relaciones sociales. Algún sabio desglosó al amor en tres aspectos, y habló de amor propio, de amor por lo propio y de amor divino. En las tres definiciones encaja Pelegrino “Nino” Menechella.</p>
            </div>
          </article>
          <div>
            <SuggestedNews 
              header="Otros Articulos" 
              news={newsData}
              className="lgs:mt-[29px] lgs:max-w-xs"
            />
            {/* <div className='mt-7'>
              <h2  className='font-inter mb-4 md:text-xl'>Compartir en:</h2>
              <div className='w-full flex justify-start'>
                <SocialShareButtons 
                  url={encodeURI(
                    "https://slvcoficial.com/nino-menechella"
                  )} 
                  title={encodeURIComponent(
                    "Client Side and Server Side"
                  )}
                />
              </div>
            </div> */}
          </div>
        </section>
    </MainLayout>
  )
}

export default NoticeDetailPage