import React from 'react';
// import New from '../../../components/New';
import NewLg from '../../../components/NewLg';

const SecNews = () => {
  return (
    <section id='noticias' className='flex w-full justify-center items-center pt-[120px] px-[5px] sm:px-[24px]'>
        <div className='w-full lgs:max-w-[1080px] max-w-[700px]'>
            <h2 className='font-nexa-thin text-center lgs:text-left text-[48px] leading-none mb-5 uppercase'>
                Nuestra <b className='font-nexa-bold'>Historia</b>
            </h2>
            <div className='flex items-center gap-7 flex-col lgs:flex-row justify-center'>
                <NewLg />
                {/* <div className='flex flex-col w-[90%] sm:flex-row gap-7 lgs:flex-col lgs:justify-between lgs:gap-[70px] lgs:max-w-[330px]'>
                    <New />
                    <New />
                </div> */}
            </div>
        </div>
    </section>
  )
}

export default SecNews