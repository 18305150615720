import React from 'react';
import { images } from '../../constants';
import MainLayout from '../../components/MainLayout';

const AboutUsPage = () => {
    return (
    <MainLayout>
        <section className='container mx-auto max-w-[1080px] flex flex-col px-5 pt-[120px] pb-[100px] lgs:flex-row lgs:gap-x-5 lgs:items-start'>
            <article className='flex flex-col items-center'>
                <h1 className='text-[30px] sm:text-[40px] font-nexa-thin uppercase mt-3 leading-[30px] sm:leading-[45px] mb-2'>ESA HERMOSA AVENTURA, <b className='font-nexa-bold bg-gradient-slvc bg-clip-text text-transparent'>LLAMADA LA 2006</b></h1>
                <div className='mt-[30px]'>
                    <img src={images.Equipo6} alt="Una hermosa aventura" className='w-[40%] max-w-[600px] min-w-[300px] float-none sms:float-left mr-0 xs:mr-4 mb-4' />
                    <div>
                        <h2 className='text-left text-[20px] sm:text-[30px] font-nexa-bold uppercase leading-[20px] sm:leading-[45px] text-solid-blue'>El Fútbol Infantil:</h2>
                        <p className='leading-7 font-inter mt-4'>El 14 de diciembre de 2014, un club llamado San Lorenzo de Villa Castells ganaba 3 a 1 la final por
                        el primer ascenso a Primera División. Hasta ese momento, para muchos de nosotros el club era una
                        institución desconocida. Nuestros hijos hacían sus primeras armas en el colegio y se divertían
                        jugando en E.Fu.R, una escuelita de fútbol que tenía sus canchas en Centenario y 505. <br /><br />
                        Entre esos chicos, que compartían colegio y deporte, estaba Bauti Giommi, unos de los nietos del
                        Gran Alfredo. Su papá, Sebastián, en la necesidad de acercar gente al club para colaborar en ese
                        nuevo desafío de enfrentar las competencias de la Primera A, nos invitó a sumarnos al Cuervo que
                        tenía un predio a pocas cuadras; en calle 10 y 505. Con el visto bueno de nuestros hijos, que
                        querían seguirjugando juntos, varios de nosotros aceptamos la invitación y nos sumamos al club.<br /><br />
                        En ese entonces faltaba de todo, tanto como sobraban valores y ganas de crecer. Era tan así que, al
                        segundo día de llevar a los chicos a entrenar, algunos de nosotros ya éramos Entrenadores y nos
                        buscaban categoría. Y casi al pasar, medio a regañadientes porque ninguno quería dirigir a sus
                        hijos, Cristian Nieva, Alejandro Rodríguez y yo, nos hicimos cargo de una categoría: justamente,
                        ¡¡¡la 2006!!!<br /><br />
                        Con cuatro pibes que estaban en el club y con
                        unos cuantos más que se iban sumando
                        empezamos a entrenar. Lo hacíamos en las
                        canchas de fútbol 5 que estaban en la entrada
                        del predio y con pelotas que llevábamos de
                        nuestras casas. Nos faltaba mucho, sí; pero el
                        primer día que conocimos a Nino y Alfredo
                        entendimos que ese iba a ser un lugar del que
                        nos íbamos a enamorar rápidamente. Y así
                        ocurrió apenas empezamos a escuchar sus
                        historias, sus luchas por mantener el club a flote
                        y esa incomparable voluntad para estar al
                        servicio de los demás, que los hizo UNICOS.<br /><br />
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[400px] float-none sms:float-right mt-4 mr-0 xs:ml-4 mb-4'>
                            <img src={images.Equipo1} alt=""  />
                        </div>
                        <p>
                        Apenas iniciados los entrenamientos, Ale tuvo que dejarnos como entrenador, pero siguió al lado
                        nuestro como padre, siempre apoyando desde donde estaba. Al mismo tiempo, los pibes no
                        paraban de aparecer, y en pocas semanas íbamos a lograr fichar al jugador 15, completando así el
                        cupo de chicos que podíamos tener en la categoría. No lo sabíamos en ese momento, pero con
                        los años nos dimos cuenta de que eso era un gran logro para nuestro pequeño club.<br /><br />
                        Los días pasaron y el primer partido llegó. Un sábado 21 de marzo del 2015, en cancha de Curuzú
                        donde éramos locales, nos tocaba enfrentar a Villa Montoro. Ese día jugamos con Ibarra; Ramiro,
                        Tomi; Giommi, Franco; Lautaro y Nieva. Y entraron en el segundo tiempo, Nicolás y Juan Cruz.<br /><br />
                        Recuerdo que por trabajo no estaba en La Plata. Llegué justo para el inicio del partido. Entré
                        corriendo al banco de suplentes mientras el árbitro empezaba el partido. Mientras saludaba a
                        Cristian, escuché el grito de gol de los visitantes. Iban 20 segundos y perdíamos 1-0... el final fue
                        un bravo 5-0. Fuimos al vestuario con mucha pesadumbre, pero ahí mismo vimos que había algo
                        especial en el grupo de pibes. A los pocos
                        minutos todos se retiraban con una sonrisa, como
                        si la derrota no los hubiera afectado. Como si
                        remar desde atrás y reponerse, fuera algo que ya
                        supieran iba a tener que hacerse. Como si el
                        superarse para ser mejor mañana fuera el objetivo
                        de hoy, como la historia del club lo demandaba.<br /><br />
                        A pesar de que dos partidos después, en cancha
                        de Porteño, Franquito Romano hacía nuestro
                        primer gol, ese primer semestre fue durísimo.
                        Catorce jugados, catorce perdidos. Muchos
                        de ellos por goleada. Pese a todo, ningún pibe se
                        fue del club y teníamos a la mayoría presente en cada entrenamiento.<br /><br />
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[400px] float-none sms:float-left mt-4 mr-0 xs:mr-4 mb-4'>
                            <img src={images.Equipo2} alt=""  />
                        </div>
                        <p>
                        En el segundo semestre estrenamos cancha propia. Después de mucho laburo en el predio, la
                        cancha de 505 estaba lista. Con el discurso de que “en nuestra cancha” nadie nos iba a ganar
                        iniciamos el Clausura. Poco nos duró, porque en la segunda fecha Alianza nos ganaba 8-1. Pero,
                        como siempre, los chicos se quedaron con lo positivo: en un contragolpe furioso, Bauti Nieva hacía
                        un golazo en el arco de los vestuarios. Era el primero de la 2006 en nuestra cancha; locura total.
                        Las derrotas se siguieron acumulando, al mismo ritmo que las ganas de los chicos de mejorar y
                        seguir apostando por ellos mismos. Y la recompensa llegó. En la fecha 12 visitábamos a Brandsen,
                        que antes del minuto nos ganaba 1-0. Cuando suponíamos que se venía otra goleada, el resultado
                        se mantuvo hasta los minutos finales. Y quizás en la única vez que pudimos pasar mitad de cancha,
                        un gran pase de Nieva a Ibarra terminó en el empate agónico. Fue tanta la alegría, que terminamos
                        dando la vuelta olímpica revoleando las camisetas.<br /><br />
                        Increíblemente, Cristian no había podido ir ese día. Tenía una bronca bárbara, después de tanto
                        esfuerzo y esperanzas, no haberlos visto festejar le dejaba un sabor amargo. Pero estos pibes
                        siempre tuvieron algo especial, y le iban a dar ese regalo que esperaba. Al partido siguiente
                        jugábamos contra Villa Lenci, de local. Dos minutos, ya perdíamos 1-0. En ese momento, Santi
                        Peralta ya hacía sus primeras armas en el arco de la 2006. Recuerdo que tuvo más de diez atajadas
                        increíbles que nos mantuvieron en partido. Hacia el final, los chicos nuestros se habían
                        envalentonado. En la última del partido nos cobran un tiro libre a favor en mitad de cancha, del que
                        Lauti se haría cargo. No voy a olvidar nunca como la pelota pegaba en el travesaño, picaba con
                        fuerza, se elevaba y tocaba la red. Fue una caricia al alma. Así como el salto de Cristian para
                        abrazarlo, fue algo difícil de no disfrutar. Tanto fue el festejo que ellos sacaron del medio y nosotros
                        estábamos todos en el banco de suplentes. Por suerte se demoraron, Santi sacó la última que tenía
                        que sacar y otro empate nos llevaba a un nuevo festejo alocado.<br /><br />
                        El primer año se fue con más derrotas, pero ya no importaba. En el inicio del segundo año teníamos
                        solo 10 chicos. Y así lo íbamos a afrontar. Las derrotas seguían, pero ya no eran tan abultadas, y se
                        mezclaban con algunos empates. En la fecha 12 jugábamos contra Ringuelet, que venía peor que
                        nosotros. Por primera vez íbamos con esa sensación de que podíamos ganar. El partido era todo
                        nuestro, hasta que llegó ese momento fatídico. Santi que ataja una buena pelota y cuando va a
                        buscar el rebote, choca su boca con la rodilla de Tomi. El resultado, gol de ellos y problemas en los
                        dientes para Santi, que hizo un esfuerzo enorme por seguir, pero no pudo. Intentamos todo el
                        partido después de eso, pero parecía que la suerte estaba echada.<br /><br />

                        En el inicio de las vacaciones de invierno fuimos a buscar algunos chicos para completar el
                        plantel. Así vinieron los mellizos San Martín y Joaco Sánchez (el papá, Marcelo, aún es parte del
                        grupo de whatsapp, pese a que Joaco no jugó nunca en nuestras juveniles). Los resultados
                        mejoraron, y hasta tuvimos partidos muy buenos con resultados que podrían habernos favorecido.
                        Pero tuvimos que esperar a la revancha con Ringuelet para poder festejar. No fue fácil bajar la
                        ansiedad de todos en la semana previa y llegamos al partido con unas ganas enormes. Fue hermoso
                        de principio a fin. A los pocos minutos Joaco Sánchez metía el 1-0 con un tiro que se escapaba al
                        arquero. Y al final del primer tiempo, Nievita hacía el segundo luego de gambetear a dos y clavarla
                        bien arriba. No sabíamos que decir en el entretiempo. La victoria de iba a completar con un golazo
                        de Franquito de volea y una definición mano a mano del melli Tomi. Era la primera victoria ¡¡¡¡¡¡y
                        era por 4 a 0!!!!!!.<br /><br />
                        Tengo tan grabados los festejos como la sensación que tuve el entrenamiento posterior. El clima era
                        el de siempre. No había euforia, solo esa contracción a mejorar, a seguir, a perseverar. Era el club
                        mismo, le representación de sus valores.<br /><br />
                        No hubo más victorias ese año. Tampoco al siguiente; año en que sumamos a los Messina. Esa
                        familia que considero MI familia, compuesta de tipos extraordinarios; y a un tipo que es uno de mis
                        hermanos de la vida; probablemente la mejor persona que conozco, Fede Amigucci.<br /><br />
                        Claro que igual nos pasaron cosas ese año. El primer partido jugábamos contra Alianza, tricampeón.
                        A los diez minutos les ganábamos 2-0 con dos goles desde mitad de cancha de Lauti. Nadie lo podía
                        creer. Nos terminaron empatando en el cuarto minuto de alargue, luego de que el árbitro diera solo
                        dos. No puedo contar a cantidad de padres expulsados que hubo ese momento. Hoy, a la distancia,
                        es una anécdota que me arranca una sonrisa. Y me enorgullece de la gente que estuvo en esos
                        momentos. Claro que nos importaba ganar, pero nadie se quejaba por los resultados, porque
                        entendíamos que el objetivo era otro.<br /><br />
                        Hubo tiempo para sufrir a la distancia, también. En mayo de ese año tuve que operarme la rodilla
                        que quedaba sana. Al día siguiente de la operación nos tocaba CRIBA, de visitante y no podía asistir.
                        Con Fede en el banco de suplentes, mi contacto era Moni, la mamá de Santi Peralta. Estuve
                        convencido que se había equivocado cuando al minuto de partido me decía que era derrota 2-0.
                        Pero no, al final fue un lapidario 8-0. Esa semana Fede entendió lo que era la 2006. Mientras él
                        llegaba medio apesadumbrado por el resultado, los chicos fueron llegando con una sonrisa. Como
                        siempre hacían. A entrenar, seguirmejorando y esperarla próxima chance de jugar.<br /><br />
                        Después de eso tuvimos cosas raras. Todas en
                        contra. Como si alguien buscara ponernos piedras
                        en el camino para que estos pibes las sorteen. Nos
                        anularon contra ADIP un gol que tocó la red,
                        porque supuestamente desde el ángulo del árbitro
                        no había entrado. Era el empate en el último
                        minuto. Nunca ví algo igual. Como el gol en contra
                        en el último minuto contra Montoro; dónde el juez
                        cobró falta, los chicos frenaron y el de ellos hizo el
                        1-0 que al final se convalidó. Ahí recuerdo los
                        expulsados, pero nunca fui botón. Fue la única vez
                        que me expulsaron; aunque en realidad me fui
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[400px] float-none sms:float-right mt-4 mr-0 xs:ml-4 mb-4'>
                            <img src={images.Equipo3} alt=""  />
                            <p className='italic font-inter text-[14px] leading-normal'>la 2006 con la bandera de apollo al vicepresidente</p>
                        </div>
                        <p>
                        solo. Jugamos tres minutos de más y recuerdo que me metí a suspenderlo porque estaba cobrando
                        todo a favor nuestro para corregir su error. Siempre voy a agradecerles a los
                        padres de la categoría por haberme apoyado en esa decisión. Estábamos formando personas, y
                        parte de la vida es hacerse cargo de los errores, para no volver a cometerlos.<br /><br />
                        En el segundo semestre nos avisaron que el partido contra ADIP iba a ser grabado y pasado por
                        televisión. En un programa que se llamaba Futbol Infantil a lo Grande. Recuerdo que solo pensaba
                        en que no nos goleen, para que los chicos pudieran tener ese recuerdo y que sea grato. Jugamos un
                        partidazo. Les pegamos un peludo inolvidable, o así prefiero recordarlo. Pero como si con
                        demostrar lo que habíamos mejorado ya alcanzara, nos conformamos con un empate en 0 para que
                        todos se fueran felices. Aún conservamos en casa el DVD.<br /><br />
                        Nuestro ciclo se terminó en ese último año del Fútbol Infantil, con un empate y tres derrotas
                        ajustadas, todas por 1-0. Mostrando que estábamos ahí, que yo no faltaba tanto. Que, como San
                        Lorenzo, seguíamos creciendo y los íbamos a alcanzar.<br /><br />
                        </p>
                        <h3>El futbol juvenil:</h3>
                        <p>El año 2018, con el inicio del fútbol juvenil de la categoría, entendimos que era tiempo de soltar.
                        Empezaba en el club un proceso maravilloso de orden y progreso de las categorías juveniles, de la
                        mano de Guillermo Lyde, al que todos conocemos como Chila. Así, mientras con Fede nos hacíamos
                        cargo de la categoría 2004, los chicos iniciaban la segunda etapa de su aventura representando a la
                        Prenovena del club, conducidos por el profe Joaquín y la profe Delfina. La adaptación no fue fácil. Se
                        sumaron chicos nuevos a la categoría, así como también se perdieron algunos otros. Fue otro año
                        sin victorias, pero iba a ser el último.<br /><br />
                        Como anécdota, creo que todos los padres recordamos el partido contra Curuzú, último campeón,
                        de local. Para sorpresa de todos, ganábamos desde temprano 1-0 con gol del melli Tomi. Así nos
                        íbamos al descanso. En el segundo tiempo no la pasábamos bien, pero los minutos se iban. Tanto se
                        iban, que en lugar de jugar 20, ya se estaban jugando veinticuatro. Bajo una ola de reclamos de
                        nuestra parte, el árbitro cobra un córner para ellos. El tiro de esquina fue mal tirado, casi al ras del
                        piso. Pero por obra del destino, la pelota pasó entre 60 piernas hasta que Lucio, un pibe categoría
                        2007 que nos había hecho el aguante de jugar, quiere rechazar y, lamentablemente, la mete en
                        nuestro arco. Fue final 1-1, que hubiera sido muy festejado, si no fuera por cómo se dio el final. Y
                        mucho peor fue al enterarnos luego que al juez se le había trabado el reloj, y no sabía cuantos
                        minutos de partido iban. Parecíamos destinados a no festejar.<br /><br />

                        En enero del 2019, por intermedio de un conocido de Alfredo, nos invitan a jugar un torneo en
                        la Ciudad de Bolívar. De todas las categorías del club, era lógico que nos iba a tocar a nosotros. En
                        una época rara, sin entrenador, con muchísimo gusto acepté volver a ponerme el buzo para dirigir
                        a la 2006. Mi ayudante fue un tal Sebastián Giommi. Era lógico que no íbamos a completar, asique
                        pedimos ayuda a otras categorías. Juntamos 17 pibes; diez 2006, cuatro 2007 y tres 2005.<br /><br />
                        Un viernes 25 de enero, bien temprano, partimos hacia Bolívar, en varios autos particulares llevando
                        a todos los pibes a la aventura. Paramos en un colegio, del que lamentablemente no recuerdo el
                        nombre. Estaba ubicado a la vuelta del Club Bolívar, donde íbamos a bañarnos. Nos habían asignado
                        dos aulas, una para que duerman los chicos, más Seba y yo; y la otra para que duerman los padres.
                        Creo que hasta el día de hoy podemos discutir si era peor soportar el olor a tanto piberío junto o los
                        ronquidos de nuestro querido Nico. <br /><br />
                        Ese mismo viernes a la tardecita
                        jugábamos el primer partido. Por
                        supuesto, no nos iban a faltar los
                        contratiempos. Ya, de entrada,
                        teníamos cuatro arqueros. Charlamos
                        con ellos y entre todos decidimos que
                        Santi y Berni (que en ese momento
                        atajaba) jugaran en sus puestos,
                        mientras que Lucas, 2005, y Lucio, 2007,
                        nos hicieran el aguante yendo al
                        medio. Una de las cosas de las más me
                        enorgullezco, es que habíamos
                        diseñado un sistema para que todos los
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[450px] float-none sms:float-left mt-4 mr-0 xs:mr-4 mb-4'>
                            <img src={images.Equipo4} alt=""  />
                        </div>
                        <p>
                        chicos jugaran la misma cantidad de minutos.<br /><br />
                        El primer partido fue una derrota 5-0 categórica. Pero nos fuimos dormir como si nada pasara.
                        Había que reponerse porque al otro día teníamos dos partidos más. Ya sabíamos que era eso de
                        reponerse, asique nos fuimos dormir con la alegría de estar viviendo algo único.
                        Al otro día llegaron Alfredo y Rosita. Porque además de ir de visita, aprovecharon que Bauti y
                        Champi, sus nietos, jugaban con sus colores del alma. Desayunamos como locos, como no podía ser
                        de otra manera si esos dos fenómenos estaban ahí. Nos preparamos y nos fuimos a la cancha.<br /><br />
                        Fue el partido más mágico que viví en San Lorenzo. A los cinco minutos ganábamos 1-0, con gol de
                        Tobi Silva. Estábamos muy felices. Pero al ratito nomás Champi, el nieto de los viejos, hizo el
                        segundo para estirar la ventaja. Recuerdo que pensé que lindo debe ser para ellos, que vinieron
                        desde Villa Castells, ver a su nieto hacer un gol. Pero esta categoría siempre nos cumplió y esta vez
                        iba a ser en grande. Cerca del final del primer tiempo, Bauti Giommi –el otro nieto- agarró un rebote
                        en borde del área grande, dominó con cara interna del pie derecho y con la misma gamba la
                        acomodó al lado del segundo palo. No solo era el tercero, era magia. Que mayor regalo que ese le
                        podían hacer estos pibes a dos personas que siempre vivieron para “el otro”. Fue el único gol que
                        hizo Bauti en sus años como jugador del club, todavía veo la cara de Seba, paradito atrás mío, con
                        lágrimas de felicidad en los ojos. Pueden decir que fue casualidad, yo prefiero creer fue la 2006. Por
                        supuesto, en el segundo tiempo ellos nos hicieron dos goles y terminamos sufriendo, pero logramos
                        ganar 3-2. Eso, también es la 2006.<br /><br />
                        El último partido nos encontró como siempre. Teníamos que ganar. Y los pibes querían ganar. Pibes
                        que estaban acostumbrados a perder, a correr de atrás; ahora sentían que se podía. Fue la única
                        vez que me importó el resultado, tanto como ser leal a las convicciones del club y a la felicidad de
                        los chicos. Y empezó nomás el partido. Y lo ganábamos a los 10 minutos. El primer gol de Nata en el
                        equipo. Ese pibe que parecía de 20 años porque les llevaba una cabeza y media a los demás, al
                        ratito se desgarraba y tenía que dejar la cancha. En el segundo tiempo ellos nos empataron y los
                        terminamos acorralando. El partido se fue con un tiro libre al área con 9 pibes nuestros yendo a
                        buscar la heroica.<br /><br />
                        Cuando volvimos al colegio solo hubo sonrisas, y ese ratito para comer un gran asado acompañados
                        de los padres que nos hicieron el aguante. También para dejar en alto al club, como corresponde.
                        Cuando llegamos, no sabíamos lo que era viajar, y el aula que nos dieron era un quilombo. No había
                        un pibe que supiera dónde estaba su ropa. El aula de al lado, dónde estaban nuestros verdugos del
                        primer partido era un lujo. Le faltaba brillar. Pero cuando nos fuimos, ellos solo se preocuparon por
                        sus cosas y el aula no quedó en las mejores condiciones. Nosotros hicimos lo que Nino y Alfredo
                        nos enseñaron. Juntamos lo nuestro, barrimos, acomodamos los colchones que nos habían
                        prestado y hasta acomodamos los bancos del colegio; porque en San Lorenzo nos educaron
                        sabiendo que el otro también importa.<br /><br />
                        Pero tuvimos que volver, porque el año seguía y la novena estaba a la vuelta de la esquina. Creo, sin
                        temor a equivocarme, que por primera vez en la historia la categoría tenía un golpe de gracia. Ïba a
                        pasar el tiempo para que nos demos cuenta, pero el destino hizo que los chicos se cruzaran un tipo
                        que los hizo no solo creer en ellos mismos, sino que les mostró el camino para superarse. Así, un tal
                        Agustín Serino se hizo cargo de la categoría. Acompañado por Santiago, un pibe de primer nivel que
                        hoy es un gran amigo me dio el club, y Salva, quién estuvo poco tiempo, pero dejó un grato
                        recuerdo. Junto a Fede tomamos el mando de la 2007, y con Nico Maitini como profe, a la vez que
                        la descocía en la primera, entrenamos todo el año juntos. Agus fue el puntapié que necesitaban los
                        chicos para crecer deportivamente, sin dudas.<br /><br />
                        A los chicos que ya estaban se sumaron algunos más, y el plantel se empezó a completar. Entre
                        ellos, llegó ese pibe por el que sigo teniendo debilidad. Gaby “el Cachetón” Bozzo fue el jugador
                        que necesitaba la categoría. No solo por su calidad de excelente persona y compañero, sino porque
                        entendía todo el juego y hacía que los demás vayan mejorando diariamente.<br /><br />
                        Así, de a poco, sin lograr los resultados aún,
                        las mejoras se empezaron a ver. Pero claro,
                        no sería la 2006 si no tuviera que recuperarse
                        de los golpes, y esos golpes iban a empezar a
                        aparecer. A mediados de mayo de ese año, de
                        golpe nos enteramos de que Alejo no se
                        sentía bien y de repente vimos como la
                        situación se fue complicando.
                        Inmediatamente el club se puso a disposición
                        de los Messina, como hace una familia
                        cuando un miembro de ella tiene problemas.
                        Recuerdo cuando fuimos a donar sangre con
                        Julieta; había no menos de seis personas en
                        ese momento que eran del club. Incluso la
                        enfermera me dijo que ya habían ido más de
                        20 personas por ese nombre. Y bueno, Alejo,
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[450px] float-none sms:float-right mt-4 mr-0 xs:ml-4 mb-4'>
                            <img src={images.Equipo5} alt=""  />
                        </div>
                        <p>
                        como buen representante de la 2006, nos enseñó que <b>NO VALE DARSE POR VENCIDO, QUE HAY QUE LUCHAR, QUE TODO SE PUEDE.</b> Y
                        él pudo. Me gusta pensar que puse mi granito de arena, para robarle un pedacito de su victoria.
                        Tiempo más tarde, lo íbamos a ver nuevamente en una cancha y hasta nos iba a regalar algunos
                        goles para que podamos desahogar esa angustia.<br /><br />
                        Antes de eso, igualmente seguirían las buenas y las malas. En cancha de Porteño, Santi Peralta se
                        iba a lesionar los ligamentos. Era increíble para la edad, pero encima no iba a ser el único. Ya que
                        tiempo después, en ese mismo año, en cancha de Everton le iba a suceder los mismo a Gaby. Santi
                        se operó, afrontando con valor una situación que no es normal para chicos de su edad y volvió
                        tiempo más tarde a los entrenamientos. Por Gaby seguimos esperando que pueda operarse y
                        solucionar ese inconveniente que, por suerte, no le ha impedido mostrarnos todo lo que sabe y
                        juega.<br /><br />
                        Hubo lugar para las alegrías también. Por supuesto que sí. Ese trabajo dedicado que habían
                        realizado los entrenadores empezaba a rendir sus frutos. En el segundo semestre se sumaban varios
                        chicos, y a pesar de que la primera del club tenía un panorama muy oscuro en su pelea por no
                        descender, la categoría 2006 lograba completar el cupo de veinticinco pibes fichados. Entre los
                        nuevos, se encontraba el Laucha Gaute, luego de haber entrenado varios meses sin poder ficharse.
                        El Laucha hacía mucha diferencia con su velocidad y gambeta, pero había llegado una semana
                        después del cierre de libro de pases, por lo que tuvo que esperar su oportunidad para poder jugar.
                        La primera victoria no iba a tardar en llegar. El rival era Malvinas, que tenía un pibe que, con edad
                        de novena, ¡¡¡había jugado el día anterior en Reserva!!! Iba a ser un rival difícil, seguramente. Ya a
                        los 40 segundos ese muchacho reventaba el travesaño del arco donde Lauti Villa hacía sus primeras
                        apariciones. Pero los chicos jugaron un gran partido y terminaron ganando 2-1, con el Laucha como
                        goleador. Todavía guardo el video del festejo en el vestuario. Ver a Santi Peralta parado,
                        aplaudiendo, y al Pitbull Giommi saltando con las muletas –con una lesión de las que tuvimos miles-
                        me emociona. Es parte del ser parte, valga la redundancia.<br /><br />
                        Luego de esa victoria, vinieron tres más en fila. CRISFA de visitante, con gol de Benja Aveldaño. Un 2
                        a 1 agónico contra Unidos de Olmos, con un cabezazo de Gonza Cresta. Y una victoria sufrida pero
                        muy festejada por 1-0 contra Curuzú, de visitante, con gol de Gaute. Algo inédito en la categoría. Y
                        por primera vez los pibes miraban la tabla de posiciones, convencidos de que se podía. Ya no era el
                        mismo grupo que empezó, obviamente, pero el espíritu parecía contagiarse a los nuevos que se
                        iban sumando.<br /><br />
                        Ese 2019 se finalizó con la obtención del Campeonato de Primera División, luego de pelear todo el
                        año el maldito descenso. Nuestro Profe Nico, fue el autor del primer gol de aquella última jornada
                        que le dio el título a nuestro club. El festejo alocado con los pibes en el alambrado será un recuerdo
                        que voy a llevar muchos años en mi memoria. Ni hablar del viaje desde el Villa Castells a la cancha
                        de Comunidad Rural, pasando por La Cabaña, donde se encontraba el plantel de primera para
                        brindarle apoyo. Fueron dos micros repletos de pibes del club, que se sumaron a tantos otros de las
                        categorías infantiles que ya estaban en Comunidad. Ese día sentí que habíamos cumplido el sueño
                        de Alfredo y Nino, de ver un club repleto de pibes.<br /><br />
                        Al año siguiente, parecía que se venía la consolidación deportiva. Ya habían quedado atrás esos
                        años de derrotas dolorosas y era frecuente verlos competir contra cualquiera. Lamentablemente la
                        pandemia nos frenó. En ese mes que pudimos compartir hicimos algo que fue muy lindo,
                        quedándonos a dormir en el club luego de pasar una tarde de entrenamiento y una cena con la
                        2005 (en esos momentos, la dirigíamos con Fede, otra vez).<br /><br />
                        A la mañana siguiente desayunamos todos
                        juntos y luego disfrutamos una jornada de fútbol
                        amistoso con todas las categorías de juveniles.
                        A los pocos días tuvimos que aislarnos y fue un
                        mazazo. Por suerte, junto a gente extraordinaria
                        como el Profe Nahuel, que se puso el equipo al
                        hombro, no perdimos el contacto con los chicos, y
                        pudimos seguir entrenando y divirtiéndonos a la
                        distancia.<br /><br />
                        En el inicio del año 2021, mientras esperábamos el
                        retorno de Alejo y veíamos como Santi Peralta
                        realizaba el intento de su vuelta, tuvimos otro
                        golpe duro. A la fractura de tobillo de Benja
                        Aveldaño, que le impidió volver por muchísimo
                        tiempo, se le sumó una rara afección en el ojo de
                        Gonzalo Ovejero. Lamentablemente, Gonza
                        tampoco pudo retomar la actividad deportiva, pero
                        no fue hasta después de intentar volver a probar.<br /><br />
                        Con el plantel consolidado, casi sin cambios del que había iniciado el año anterior, volvimos a las
                        canchas apenas se levantó la restricción por la pandemia. Ese año me sumé como colaborador de
                        Agustín, quién ya estaba acompañado por Adriano. En la primera ronda no nos fue tan bien, pero
                        siempre estuvimos en competencia. Nos ganaban, claro, como a todo el mundo, pero también les
                        ganábamos nosotros.<br /><br />
                        En la segunda ronda, un torneo
                        corto de dos zonas donde
                        clasificaban los dos primeros, los
                        chicos nos hicieron ilusionar a
                        todos. No solo se dio la vuelta de
                        Alejo, ahora reconvertido en
                        delantero, sino que, además,
                        ganamos en lo deportivo. El
                        equipo peleó la clasificación hasta
                        la anteúltima fecha, ganando
                        mucho más de lo que perdió. Un
                        partido increíble, perdido 3-2 en
                        cancha de Porteño, terminó
                        </p>
                        <div className='w-[40%] min-w-[250px] max-w-[400px] float-none sms:float-left mt-4 mr-0 xs:mr-4 mb-4'>
                            <img src={images.Equipo6} alt=""  />
                        </div>
                        <p>
                        sellando nuestro destino ese año.<br /><br />
                        Semanas más tarde, terminaba el ciclo de Agustín al frente de la categoría. Para mí, un antes y un
                        después. Con sus ganas, su mensaje y sus convicciones logró transformar a un grupo de pibes que
                        se llevaba muy bien y disfrutaba de jugar, en un grupo de pequeños hombres que tenían ambición
                        deportiva; y todo eso, sin perder la esencia de quienes eran. Eso es lo más valioso de toda su etapa
                        con nuestra 2006.<br /><br />
                        Para el año siguiente, Alejandro se hizo cargo de la conducción de la, por entonces, sexta división.
                        Siendo que estaba solo, pidió una mano y ahí estuvo Marito. Y vaya que le dio una mano. No solo
                        colaboró con el Entrenador, sino que en muchos momentos tomó las riendas del grupo.<br /><br />
                        Despedida de la 2006 en lo de Mario, con toda la banda
                        Con ellos, manteniendo la base de chicos que estaban en el plantel, los resultados positivos se
                        volvieron más habituales. Como el año anterior, el primer torneo costó, pero en el segundo los
                        chicos levantaron y terminaron el año bastante arriba.<br /><br />
                        En cuanto a las malas noticias, este año no iba a ser la excepción. A mediados de año, en una jugada
                        simple en mitad de cancha contra Estrella de Berisso, Ramiro sufría un golpe que lo llevaba a
                        realizar un mal movimiento. El resultado de eso, luego de un tiempo de incertidumbre, fue una
                        nueva rotura de ligamentos en la categoría, con operación realizada el 21 de octubre de ese año.
                        Otro chico nuestro más que pasaba por una situación compleja y que tenía que enfrentarla con
                        hidalguía. Y vaya que lo hizo. Luego de una recuperación perfecta, más de un año después, lo
                        veíamos regresar a defender su camiseta en una cancha. Fue otra prueba de lo que son estos pibes.
                        Pero como siempre, y siendo difícil de creer, en ese mismo partido donde se golpeaba Rami, le
                        ganábamos por primera vez en la historia a Estrella de Berisso por uno a cero. Y el gol, como creo no
                        podía ser de otra manera, era obra de Alejo –el primero de su cuenta personal en partidos oficiales-
                        . Una vez más la 2006 nos ponía en la satisfacción de la recuperación de uno y en la preocupación
                        por los inconvenientes de otro. Como si ese sube y baja de emociones que fue esta historia no nos
                        diera respiro.<br /><br />
                        Hubo tiempo para más cosas. Jugando en cancha de Malvinas, Tomi trabó mal en mitad de cancha,
                        y se lastimó el tobillo. Otro a recuperación y con una ausencia de unos cuantos meses.<br /><br />
                        También hubo tiempo ese año para los
                        “locuras” de La Liga. Tras problemas
                        dirigenciales, se decidió la intervención y el
                        torneo se suspendió durante un tiempo. En
                        ese lapso se realizaron diversos amistosos,
                        donde los chicos consiguieron el resultado
                        más abultado de su historia.<br /><br />
                        Al volver la actividad oficial los chicos tuvieron
                        algunos resultados alentadores, que otra vez
                        los animaron a ir por más. Ese año terminó
                        con buenas actuaciones y con la ilusión de
                        alcanzar mayores metas en la última etapa.<br /><br />
                        Este 2023 arrancó movido. Después de un año acompañando a la categoría solo en rol de padre, y
                        luego de una charla con Mario y Ale, que me hicieron el lugar, retorné como ayudante en los
                        entrenamientos. Duré solo 20 minutos, que fue lo que tardaron Nacho e Iván en pedirme que de
                        una mano en la 2009. ¡¡¡Creo que los chicos respiraron aliviados cuando me vieron irme a otro
                        grupo!!!<br /><br />
                        Otra vez, como si fuera una costumbre, el inicio del torneo fue irregular. Pero ya nadie nos pasaba
                        por arriba. Ni siquiera ese equipo temible que fue Brandsen, al que tuvieron que ayudar mucho para
                        que nos pueda ganar en el último minuto.<br /><br />
                        Como si no nos hubieran pasado demasiadas cosas, luego de algunas idas y vueltas, se ficharon
                        Juanjo, Juan Pablo y Edwin. Tres representantes de la bella Colombia. De repente, ahí la teníamos a
                        la 2006, fichando pases internacionales.<br /><br />
                        Pero en mayo de este año, todo quedó
                        a un lado. El 19 de ese mes, nos desayunaba
                        con la triste noticia de la partida a la
                        eternidad de nuestro querido Nino. Ni al
                        cielo, ni al infierno, ese viejo loco que tanto
                        dio sin esperar nada a cambio, se nos fue a
                        pasear por las instalaciones del Nido, a
                        disfrutar de cada pibe que patee una pelota
                        en su amado club, su casa. No alcanzarían
                        las palabras para describir y agradecer todo
                        lo que este tipo hizo; por lo que no voy a
                        intentar meterme ahí.<br /><br />
                        Claro, un par de semanas antes, Nata recibía
                        un golpe fuerte, en la misma zona donde ya
                        había tenido otro golpe fuerte y terminaba
                        en reposo para que la cosa no pase a
                        mayores. Y, como si con eso ya no bastara, a
                        Alejo lo internaban nuevamente. Con toda
                        suerte, esta vez la cosa iba a ser más leve y
                        rápidamente volvería a su casa.
                        Nuevamente, como tantas otras veces, los
                        chicos se seguían reponiendo de los golpes
                        y apoyándose entre ellos.<br /><br />
                        A las pocas semanas, solo nos quedaba recuperar a Rami. Era cuestión de tiempo para tenerlo
                        nuevamente en una cancha. Pero el destino nos guardaba una prueba más. Las más dura, como
                        familia, que hemos atravesado como parte de esta hermosa locura. En cancha de Alumni, cuando
                        las cosas venían más que bien y los chicos se habían ilusionado con pelear un torneo hasta el final,
                        Lauti, nuestro Lauti, fue a patear un tiro libre y se terminó lastimando.<br /><br />
                        Desde el primer momento temimos lo peor.
                        Y el tiempo nos lo iba a confirmar. Era la
                        cuarta rotura de ligamentos en la categoría.
                        Y, a su vez, significaba el final de su etapa
                        como jugador de la 2006, adentro de una
                        cancha. Hoy estamos en esa etapa de la
                        recuperación, con la seguridad que todo va
                        a salir bien y pronto lo volveremos a ver
                        disfrutando de patear una pelota. Al fin y al
                        cabo, es un 2006 de San Lorenzo de Villa
                        Castells, y estos tipos <b>¿no se han pasado los
                        años demostrándole al mundo que son
                        capaces de recuperarse de todo?</b> <br /><br />
                        A las pocas semanas, como era de esperarse, pudimos darnos el gusto de ver como Rami volvía a
                        las canchas. Había pasado poco más de un año. Largo, muy largo, pero con la felicidad de haber
                        superado un inconveniente más. Si bien nos habíamos ilusionado, los últimos resultados no nos
                        acompañaron y en las fechas finales nos quedamos sin chances de pelear el torneo. <br /><br />
                        El final,seguramente no fue el esperado. Por diversos motivos, nos quedamos sin la chance de
                        poder despedir a nuestros hijos en un campo de juego, como se hubieran merecido. Prefiero no
                        buscar responsabilidades sobre esto. Déjenme creer y acompáñenme en la idea de que quizás el
                        destino haya querido que este hermoso grupo no tenga un final, para que sea eterno y perdure por
                        siempre.<br /><br />
                        Supongo que ya todos deben saber que este ha sido mi último año en esta etapa en el club. El año
                        próximo iniciaremos una nueva aventura en otros lares. Sé, y estoy convencido de ello, que nada
                        será igual a esta 2006. En todos estos años que estuve en el Cuervo, tuve la suerte de dirigir la
                        2004, la 2005, la 2007, la 2009, colaborar con la 2003, la 2008, la 2010 y la 2011, ser Coordinador
                        de infantiles y de juveniles; todas categorías llenas de buena gente y de pibes excepcionales. Pero
                        créanme, familias, que no hubo en todo este tiempo una categoría que representara de mejor
                        manera la historia de resiliencia constante que tuvo San Lorenzo de Villa Castells en sus años de
                        vida, como lo hizo nuestra gran 2006. Nadie tuvo que enfrentar tantos inconvenientes y logró poder
                        salir airoso de cada uno de ellos. Nadie se mantuvo tan firme en sus convicciones y siguió
                        apostando por sus posibilidades, aunque pareciera ya no haber chances de nada. Donde otros se
                        hubieran caído, este grupo de chicos, los viejos y los no tan viejos, supieron seguir adelante y
                        reponerse de todo.<br /><br />
                        Hubiera querido ser mejor entrenador, para poder volcarles mucho más de lo que pude enseñarles.
                        Pero termino estas líneas diciendo que ha sido un enorme orgullo ser parte de este grupo, de que
                        los chicos me dejen ser parte. Querida 2006, ustedes fueron, son y serán el fiel reflejo de lo que
                        pregonaron <b>NINO y ALFREDO.</b> ¿Acaso hay algún logro deportivo que pueda igualar eso?<br /><br />
                        Donde vayan, emprendan lo que emprendan, recuerden lo que lograron. Eso que nos enseñaron
                        todos estos años. Podemos tener muchos golpes y tropiezos en la vida; pero si nos levantamos y
                        seguimos intentando, <b>NADA NI NADIE VA A PODER DERROTARNOS.</b><br /><br />
                        Gracias por esta hermosa aventura llamada la 2006. ¡Siempre con la 2006!</p>
                    </div>
                </div>
            </article>
        </section>
    </MainLayout>
)
}

export default AboutUsPage