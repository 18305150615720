import React from 'react';
import Sponsors from './Sponsors';
import { images } from '../constants';
import { FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <section className='shrink-0'>
      <Sponsors bg={'bg-gradient-blue'}/>
      <div className='flex flex-col w-full justify-center items-center bg-gradient-bordo'>
        <div className='flex flex-col gap-[40px] sm:gap-0 sm:flex-row justify-between items-center w-full max-w-[1080px] py-[40px] px-[15px] sm:px-[24px]'>
          <div className='w-[200px] flex justify-center sm:justify-start'>
            <a href="/" title='Inicio'><img className='h-[150px] sm:h-[90px]' src={images.Logo} alt="Logo San Lorenzo de Villa Castells" /></a>
          </div>
          <ul className='flex gap-[20px]'>
            <li>
              <a target="_blank" title='Instagram San Lorenzo' rel='noreferrer' className='w-[40px] h-[40px] text-white text-[40px] opacity-80 focus:opacity-100 hover:opacity-100 transition ease-in-out' href='https://www.instagram.com/slvc.oficial/'>
                <FaInstagram />
              </a>
            </li>
            <li>
              <a target="_blank" title='Youtube San Lorenzo' rel='noreferrer' className='w-[40px] h-[40px] text-white text-[40px] opacity-80 focus:opacity-100 hover:opacity-100 transition ease-in-out' href='https://www.youtube.com/@slvc.oficial'>
                <FaYoutube />
              </a>
            </li>
            <li>
              <a target="_blank" title='Facebook San Lorenzo' rel='noreferrer' className='w-[40px] h-[40px] text-white text-[40px] opacity-80 focus:opacity-100 hover:opacity-100 transition ease-in-out' href='https://www.tiktok.com/@cuervosdecastells'>
                <FaTiktok />
              </a>
            </li>
          </ul>
          <div className="flex flex-col">
              <p className='font-nexa-thin text-[11px] text-white'>Diseñado y desarrollado por:</p>
              <a href="https://bemanagement.pro/" target="_blank" title='Marketing 360°' rel='noreferrer'><img className="w-[200px]" src={images.BeManagement} aria-label="bemanagement-logo" /></a>
          </div>
        </div>
        <div className='w-full bg-black px-5'>
          <p className='font-nexa-thin text-center uppercase text-[11px] text-white'>Copyright 2023, San lorenzo de villa castells. Todos los derechos reservados.</p>
        </div>
      </div>
    </section>
  )
}

export default Footer