import React from 'react';

const Family = () => {
  return (
    <section id='familia' className='flex w-full flex-col justify-center items-center pt-[100px]'>
        <div className='w-full max-w-[1080px] pb-[30px] px-4'>
            <h2 className='font-nexa-thin text-[48px] leading-[50px] uppercase mb-2'>Forma parte de esta <br /><b className='font-nexa-bold bg-gradient-slvc bg-clip-text text-transparent'>Hermosa Familia</b></h2>
            <p className='font-nexa-thin text-[20px] leading-7 uppercase'>Acercate al nido, <b>Calle 7 y 485</b>, La plata, buenos aires<br />o Comunicate al <u>221-123-4567</u> o al <u>221-891-2345</u></p>
        </div>
        <div className='w-full h-[300px] border shadow-md z-10'>
          <iframe
            title="Mapa de Google"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3273.6088323636086!2d-58.02461389939831!3d-34.86605494297835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a2de0c06ad6561%3A0x957ccb6adbd3bc7c!2sClub%20San%20Lorenzo%20de%20Villa%20Castells!5e0!3m2!1ses!2sar!4v1710445018896!5m2!1ses!2sar"
            width="100%"
            height="100%"
            style={{ border: 0, }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </section>
  )
}

export default Family