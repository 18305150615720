import React from 'react';
import { Link } from 'react-router-dom';
import { images } from '../constants';

const newContent = [
  {category: 'HISTORIA', img: images.Nino1, link:'/nino-menechella', title: 'Una vida de película', key:'1'},
  {category: 'INFANTIL', img: images.Equipo1, link:'/hermosa-aventura', title: "Una Hermosa Aventura", key:'2'},
];

const SingleNew = ({title,cat,img,link}) => {
  return (
    <div className="flex items-center bg-gradient-white shadow-box rounded-box w-[275px] h-[120px] sm:h-[130px] sm:w-[330px]">
      <img src={img} alt={title} className='w-[40%] sm:w-[130px] h-[120px] sm:h-[130px] object-cover'/>
      <div className='flex flex-col items-start p-[11px] w-full h-full'>
        <span className='text-[14px] font-nexa-thin uppercase'>{cat}</span>
        <h2 className='font-zing-rust text-[17px] uppercase leading-[15px]'>{title}</h2>
        <Link className='flex px-[5px] py-[3px] rounded-box items-center text-white bg-solid-bordo-dark shadow-button justify-center uppercase font-nexa-thin text-[10px] mt-[4px] hover:bg-solid-bordo focus:bg-solid-bordo transition ease-in-out duration-300' to={link}>Leer más</Link>
      </div>
    </div>
  );
};

const New = () => {
  return (
    <div className='flex w-[300px] h-auto flex-col items-center gap-6 xs:gap-10'>
      {newContent.map((news) =>(
        <SingleNew key={news.key} img={news.img} brand={news.brand} title={news.title} cat={news.category} link={news.link}/>
      ))}
    </div>
  )
}


<div className='hidden sm:flex w-[330px] h-[300px] flex-col items-center gap-10'>

</div>





// const New = ({className}) => {
//   return (
//     <div className={`flex items-center bg-gradient-white shadow-box rounded-box w-full h-[150px] sm:h-[130px] sm:w-[330px] ${className}`}>
//       <img src={images.New1Image} alt="" className='w-[40%] sm:w-[130px] h-[150px] sm:h-[130px] object-cover'/>
//       <div className='flex flex-col items-start p-[11px] w-full h-full'>
//         <span className='text-[14px] font-nexa-thin uppercase'>Regional</span>
//         <h2 className='font-zing-rust text-[17px] uppercase leading-[15px]'>Golazo al ultimo minuto para la clasificación del cuervo</h2>
//         <a className='flex px-[5px] py-[3px] rounded-box items-center text-white bg-gradient-bordo-dark shadow-button justify-center uppercase font-nexa-thin text-[10px] mt-[4px] hover:bg-solid-bordo-dark focus:bg-solid-bordo-dark transition ease-in-out duration-300' href="/noticia/1">Leer más</a>
//       </div>
//     </div>
//   )
// }

export default New