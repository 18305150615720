import React from 'react';
import { Link } from 'react-router-dom';
import { images } from '../constants';

const NewLg = ({className}) => {
    return (
    <div className={`flex flex-col xs:flex-row items-center shrink-0 bg-gradient-white shadow-box rounded-box h-[450px] xs:h-[300px] w-[90%] sm:h-[340px] sm:max-w-[980px] ${className}`}>
        <img src={images.Postal} alt="" className='w-full xs:w-[45%] sm:w-3/5 h-[300px] xs:h-full object-cover'/>
        <div className='flex flex-col justify-between items-start p-[11px] w-full sm:w-2/5 h-full'>
            <div>
                <span className='text-[16px] font-nexa-thin uppercase'>San Lorenzo de Villa Castells</span>
                <h2 className='font-zing-rust text-[22px] uppercase leading-[20px]'>La Historia del Club de Barrio Mas Lindo del Mundo</h2>
            </div>
            <Link className='flex px-[5px] py-[3px] rounded-box items-center text-white bg-solid-bordo-dark shadow-button justify-center uppercase font-nexa-thin text-[14px] lg:text-[16px] mt-[4px] hover:bg-solid-bordo focus:bg-solid-bordo transition ease-in-out duration-300' to="/nuestra-historia">Leer más</Link>
        </div>
    </div>
    )
  }
  
  export default NewLg