import Logo from '../assets/Logo.png';
import Hero from '../assets/images/Hero.jpg';
import New1Image from '../assets/news/new1.jpg';
import New2Image from '../assets/news/new2.png';
import Sponsor1Logo from '../assets/sponsors/sponsor1.svg';
import Sponsor2Logo from '../assets/sponsors/sponsor2.svg';
import Sponsor3Logo from '../assets/sponsors/sponsor3.svg';
import Sponsor4Logo from '../assets/sponsors/sponsor4.svg';
import Advertisement from '../assets/sponsors/advertisement.png';
import NuestraHistoria from '../assets/images/nuestraHistoria.png';
import ElNido from '../assets/images/elNido.png';
import BeManagement from '../assets/icons/bemanagement.png';
import Alfredo from '../assets/historia/alfredo.jpg';
import AlfredoCasero from '../assets/historia/alfredo-casero.jpg';
import AlfredoEscudo from '../assets/historia/alfredo-escudo.jpg';
import Metegol from '../assets/historia/metegol.jpg';
import Nino from '../assets/historia/nino.jpg';
import Postal from '../assets/historia/postal.jpg';
import Rosa from '../assets/historia/rosa.jpg';
import Equipo1 from '../assets/2006/2006.jpeg';
import Equipo2 from '../assets/2006/2006-2.jpeg';
import Equipo3 from '../assets/2006/2006-3.jpeg';
import Equipo4 from '../assets/2006/2006-4.jpeg';
import Equipo5 from '../assets/2006/2006-5.jpeg';
import Equipo6 from '../assets/2006/2006-6.jpeg';
import Nino1 from '../assets/nino/Nino1.jpeg';
import Nino2 from '../assets/nino/Nino2.jpeg';
import Nino3 from '../assets/nino/Nino3.jpeg';
import Nino4 from '../assets/nino/Nino4.jpeg';
import Nino5 from '../assets/nino/Nino5.jpeg';
import Nino6 from '../assets/nino/Nino6.jpeg';
import Nino7 from '../assets/nino/Nino7.jpeg';
import Nino8 from '../assets/nino/Nino8.jpeg';
import Nino9 from '../assets/nino/Nino9.jpeg';
import Nino10 from '../assets/nino/Nino10.jpeg';
import Nino11 from '../assets/nino/Nino11.jpeg';
import Nino12 from '../assets/nino/Nino12.jpeg';
import Nino13 from '../assets/nino/Nino13.jpeg';
import Nino14 from '../assets/nino/Nino14.jpeg';
import Nino15 from '../assets/nino/Nino15.jpeg';
import Nino16 from '../assets/nino/Nino16.jpeg';
import Nino17 from '../assets/nino/Nino17.jpeg';
import La2019 from '../assets/images/slvc-2019.jpeg';

const images = {
    La2019,
    Logo,
    Hero,
    New1Image,
    New2Image,
    Sponsor1Logo,
    Sponsor2Logo,
    Sponsor3Logo,
    Sponsor4Logo,
    Advertisement,
    ElNido,
    NuestraHistoria,
    BeManagement,
    Alfredo,
    AlfredoEscudo,
    AlfredoCasero,
    Metegol,
    Nino,
    Postal,
    Rosa,
    Equipo1,
    Equipo2,
    Equipo3,
    Equipo4,
    Equipo5,
    Equipo6,
    Nino1,
    Nino2,
    Nino3,
    Nino4,
    Nino5,
    Nino6,
    Nino7,
    Nino8,
    Nino9,
    Nino10,
    Nino11,
    Nino12,
    Nino13,
    Nino14,
    Nino15,
    Nino16,
    Nino17,
};

export default images;