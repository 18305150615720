import React, { useState } from 'react';
import { images } from '../constants';
import { VscMenu, VscChromeClose } from "react-icons/vsc";

const navItemsInfo = [
    { name: "Inicio", id: "/#inicio" },
    { name: "Historia", id: "/#noticias" },
    { name: "Nosotros", id: "/#nosotros" },
    { name: "Familia", id: "/#familia" },
];

const NavItem = ({ name, id, closeNav }) => {
    const handleClick = () => {
        // Cerrar la navegación
        closeNav();
    };

    return (
        <li className='opacity-75 hover:opacity-100 focus:opacity-100 transition ease-in-out py-5 md:py-0'>
            <a href={id} onClick={handleClick}>
                {name}
            </a>
        </li>
    );
};

const Header = () => {
    const [navIsVisible, setNavIsVisible] = useState(false);

    const navVisibilityHandler = () => {
        setNavIsVisible((currentState) => !currentState);
    };

    const closeNav = () => {
        setNavIsVisible(false);
    };

    return (
        <section className='relative z-50'>
            <header className='h-22 w-full text-white shadow-[1px_1px_10px_rgba(0,_0,_0,_0.5)] bg-gradient-bordo fixed top-0 left-0 right-0'>
                <div className='w-full h-6 bg-solid-blue flex items-center justify-center'>
                    <div className='font-nexa-thin uppercase text-xs sm:text-smi text-right px-3 xs:px-8 w-full max-w-6xl'>
                        sitio oficial club san Lorenzo de villa Castells
                    </div>
                </div>
                <div className='h-16 container mx-auto px-8 flex justify-between items-center text-normal max-w-6xl'>
                    <div className='relative'>
                        <a href="/" title='Inicio'><img className='h-14 xs:h-28' src={images.Logo} alt='logo' /></a>
                    </div>
                    <div className='md:hidden z-50'>
                        {navIsVisible ? (
                            <VscChromeClose className='w-6 h-6' onClick={navVisibilityHandler} />
                        ) : (
                            <VscMenu className='w-6 h-6' onClick={navVisibilityHandler} />
                        )}
                    </div>
                    <nav className={`${navIsVisible ? "right-0" : "-right-full"} z-[49] flex flex-col w-full md:w-auto md:flex-row justify-center items-center md:items-end md:justify-end fixed top-0 bottom-0  md:static bg-gradient-blue md:bg-none transition-all duration-300`}>
                        <ul className='z-[48] flex flex-col md:flex-row gap-x-5 uppercase font-zing-sans items-center leading-loose text-center'>
                            {navItemsInfo.map((item) => (
                                <NavItem key={item.name} name={item.name} id={item.id} closeNav={closeNav} />
                            ))}
                        </ul>
                    </nav>
                </div>
            </header>
        </section>
    );
};

export default Header;