import React from 'react';

const SuggestedNews = ({ className, header, news = [] }) => {
  return (
    <div 
        className={`w-full shadow-box rounded-xl p-4 ${className}`}
    >
        <h2 className='font-nexa-thin uppercase font-medium sm:text-[24px]'>{header}</h2>
        <div className='grid gap-y-5 mt-2 sm:grid-cols-2 sm:gap-x-5 lgs:grid-cols-1'>
            {news.map((item) => (
                <div 
                  key={item._id} 
                  className='flex space-x-3 flex-nowrap items-center'
                >
                  <img 
                    className='aspect-square object-cover rounded-box w-1/5'
                    src={item.image} 
                    alt="proximo partido" 
                  />
                  <div className='text-sm font-inter font-medium'>
                    <h3 className='xs:text-[16px] font-nexa-bold md:text-base lgs:text-[18px]'>{item.title}</h3>
                    <span className='text-[11px] opacity-60'>
                      {new Date(item.createdAt).toLocaleDateString("es-ES", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </span>
                  </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default SuggestedNews