import React from 'react';
import { images } from '../../constants';
import MainLayout from '../../components/MainLayout';

const AboutUsPage = () => {
  return (
    <MainLayout>
        <section className='container mx-auto max-w-[1080px] flex flex-col px-5 pt-[120px] pb-[100px] lgs:flex-row lgs:gap-x-5 lgs:items-start'>
            <article className='flex flex-col items-center'>
                <h1 className='text-[20px] sm:text-[40px] font-nexa-thin uppercase mt-3 leading-[20px] sm:leading-[45px] mb-2'>La Historia del Club de Barrio Mas Lindo del Mundo, <b className='font-nexa-bold bg-gradient-slvc bg-clip-text text-transparent'>San Lorenzo de Villa Castells.</b></h1>
                <div className='rounded-box object-cover w-full md:max-h-[500px] md:max-w-[1000px] aspect-video'>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/neRhGSJgV30?si=s7S1nAhANW48-XwC" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <div className='mt-[60px]'>
                    <img src={images.Postal} alt="Postal de una Historia" className='w-[600px] float-left mr-4 mb-4' />
                    <div>
                        <h2 className='text-left text-[20px] sm:text-[30px] font-nexa-bold uppercase leading-[20px] sm:leading-[45px] text-solid-blue'>“Todo lo hacemos en función de los chicos”</h2>
                        <i>Por Federico Cejas</i>
                        <p className='leading-7 font-inter mt-4'>Parecen el shing y el shang pero no: la complicidad es absoluta a pesar de las diferencias de carácter. Entonces Nino le grita a Pampa (la perra del club), la putea y Alfredo le pide bajar el tono por la grabación. Nino saca cagando a los pibes del metegol hasta que termine la entre vista y Alfredo vuelve a hacerlo en tono cordial, pidiendo por favor. Uno casado y el otro divorciado; uno fuma y juega, el otro ni por asomo; ambos coinciden en el vinito pero sobre todo en la certeza que los guía: hace 29 años soñaron con armar un club que diera contención a los chicos que terminaban las Infantiles de Villa Rivera y hoy es realidad. San Lorenzo de Villa Castells existe por el tesón, amor propio y solidaridad que movilizan a Alfredo Giommi y Nino Menechella, los alma matter del club. O Alfredo y Nino, como todo el mundo liguista los conoce Apenas ingresados al predio que San Lorenzo luce orgullosamente en 5 y 485, se nota un club organizado, en armonía. En la puerta nos recibe la primera dama, Rosa Beatriz Folino de Giommi, y dentro de la zona de buffet y recreación la charla comienza con Alfredo relatando los inicios del club que, con 29 años recién cumplidos, por fin puede sentirse en su casa definitivamente: “Esto nació en cancha de Villa Rivera, ahí en 11 y 520, donde nos conocimos con Nino e hicimos relación.
                        <div className='w-[95%] max-w-[300px] float-right mt-4 ml-4 mb-4'>
                            <img src={images.AlfredoEscudo} alt=""  />
                            <p className='italic font-inter text-[14px] leading-normal'>“En la situación en la que estábamos, sólo un inconsciente podía acompañarnos y traer a los chicos. Seguimos adelante por amor propio”<br />~ Alfredo Giommi</p>
                        </div>
                        <br />Él fue el iniciador de ese club y pasados unos años se nos ocurrió que los chicos debían tener continuidad en cancha de 11 para seguir participando, compitiendo”. A lo que agrega que “cuando propusimos hacer Juveniles nos dijeron que no y ahí nos fuimos con Nino, intrusamos un terreno que era de Salud y en 4 meses construimos un predio muy lindo y prolijo en 10 y 505... eso fue para el inicio de los años 90. Colaboró mucha gente y fundamos San Lorenzo de Villa Castells el 10 de noviembre de 1990”. Las peripecias que vivieron Nino y Alfredo para conseguir que San Lorenzo tuviera un hogar fijo son incalculables.
                        Primero forjando el consabido predio de 505 y 10, al primero que sintieron hogar y que fue expropiado: “Luego de construir semejante lugar queríamos que nos den las tierras.
                        <div className='w-[95%] max-w-[300px] float-left mt-4 mr-4 mb-4'>
                            <img src={images.Nino} alt=""  />
                            <p className='italic font-inter text-[14px] leading-normal'>“Hay casas cerca de AMEBS que tienen nuestros bancos de suplentes en el jardín... se quedaron con todo” <br />~ Nino Menechella</p>
                        </div>
                        Se hizo un proyecto de ley, se cae en los primeros dos años, luego lo levantamos y conseguimos que en Senadores y Diputados nos votaran la donación de tierras pero cuando debían reglamentarla, la vetaron”, dice Alfredo, y con suenjundia habitual se arrebata Nino: “Lo último que hizo Ruckauff fue vetar esa ley. Eso era de Salud y todos los chorros le cambiaron el dominio, se lo pasaron a Vivienda y construyeron un barrio... todo negocio inmobiliario”. Mientras Alfredo sentenció: 
                        “Había gente de AMEBS metida en eso (NdR: Asociación Mutualde Empleados de Bienestar Social). Hicieron eso para que desaparezca San Lorenzo y AMEBS ocupara su lugar”. <br /> Posteriormente a la quita, que continúa en juicio para recuperar los materiales que San Lorenzo tenía en el predio que hoy ocupa AMEBS, estos magnánimos luchadores no se dieron por vencidos y desde la angustia hicieron fluir fuerzas para crear un nuevo complejo en 508 y 11 y para los Juveniles en 514 y 25, frente a La Plata FC, experiencia que cuenta Alfredo en primera persona: “En la desesperación que nos agarró por quedarnos sin nada, empezamos a trabajar unos terrenos frente a la cancha de La Plata FC. Con 40 grados íbamos con Nino meta pala a desmalezar 2,5 hectáreas... fue inhumano. <br />Pero en unos meses armamos tres canchas de 11 y dos de 7 que después terminaron usando las Juveniles de La Plata. Con Nino coincidimos en que si nos sacan algo nuestro, vamos para adelante por orgullo, por amor propio. Lo que nos pasó fue triste por la precariedad en que quedamos: con menos gente, los pibes en la calle, no teníamos dónde entrenar... pero así y todo lo sacamos adelante”. Algo que Nino resume descarnadamente: “En esa época cada fin de semana los chicos nos preguntaban "¿Dónde jugamos?", y les decíamos que no sabíamos... alquilamos en Porteño, Comunidad Rural... era una locura”.</p>
                    </div>
                </div>
                <div>
                    <h2 className='text-left text-[18px] sm:text-[22px] font-nexa-thin uppercase mt-1 leading-[20px] sm:leading-[45px] italic'>La Casa en el Club</h2>
                    <p className='leading-7 font-inter mb-4'>El nacimiento de sus hijos les habrán dado los días más felices a Nino y Alfredo. Luego habrán sido los nietos, los sobrinos, hijos de amigos... pero sin dudas en los últimos años la llegada definitiva al predio de 10 y 485 los habrá conmovido como pocas cosas en la vida. Tanto que hasta Alfredo está viviendo en el mismísimo predio del club. Por fin dejar de lado las angustias para darle paso a la felicidad por al menos los próximos 40 años... y sí, más que merecido: “Luego de un tiempo "gitaneando" fuimos a ver a Pocho Prandini y conseguimos estos terrenos durante 20 años, era la mitad de lo que es hoy. Nos hicieron una ordenanza y vinimos a Kosovo... jaja... hace unos meses salió otra ordenanza y nos extendieron la cesión por otros 20 años” cuenta Alfredo, y suma que “cuando empezábamos a trabajar, vino la policía a decirnos que no se podía hacer nada porque una persona había hecho un juicio de posesión.
                    <div className='w-[95%] max-w-[400px] float-right mt-4 ml-4 mb-4'>
                        <img src={images.Rosa} alt=""  />
                        <p className='italic font-inter text-[14px] leading-normal'>Rosa Beatriz Folino de Giommi, infaltable e incansable primera dama del Cuervo</p>
                    </div>
                    Lo contrarrestamos pero tardamos 7 años hasta poder meternos, cosa que finalmente sucedió en 2014. Hicimos tanto movimiento, tanto hemos mangado... Cuando te proponés una meta y llegás, es lo mejor que te puede pasar en la vida”.
                    A la hora de contar por qué se construyó la casa dentro del mismísimo predio del club, relató el mandamás Cuervo:“Esto estaba pelado. El primer análisis que hago es que tenía que cuidar alguien y era muy precario para un casero. Para hacerlo ordenado tenés que hacerle una vivienda, pagarle un sueldo en blanco... todo muy costoso. Entonces vine y de a poquito me empecé a hacer la casa yo. Las primeras dos veces que vinimos con mi señora nos afanaron y ahí dije 'para qué mierda hice esto'... era la nada misma acá. La idea siempre fue que la casa quedara para el club. Ya está hecha la donación. Eso sí, pedí un usufructo porque voy a seguir viviendo ahí”. Mientras su ladero más fiel echa más luz para algún distraído que pueda pensar mal: “Le digo a todos: no se crean que Alfredo no tenía casa. Él vivía en diagonal 73 y 59... la gente a veces se queja demasiado. Capaz que no saben que la pagó él, laburó él... desde el primer día Alfredo aclaró que la casa es del club”.
                    </p>
                </div>
                <div>
                    <h2 className='text-left text-[18px] sm:text-[22px] font-nexa-thin uppercase mt-1 leading-[20px] sm:leading-[45px] italic'>Ellos, la amistad</h2>
                    <p className='leading-7 font-inter mb-4'>El fútbol los juntó y San Lorenzo los unió. Hace más de 30 años se vieron por primera vez en Villa Rivera y no dieron testimonio de haberse separado. Laconstrucción de una amistad así tiene bases fuertes como la confianza recíproca, pero también el trabajo en equipo, la complicidad, los coincidencias de fondo. Algo que Alfredo resume cuando relata el epílogo del tiempo de gestión de ambos:“Siempre hablamos con Nino y pensamos en cuánto nos queda a este ritmo... él tiene 67 y yo 66. ¿A los 70 bajamos la cortina, ponele? Cuando empezamos hacíamos los cercos con hilo y antes de pintar las canchas teníamos que juntar la mierda de las vacas que pastaban por ahí... hoy estamos muy bien”.
                    <div className='w-[95%] max-w-[500px] float-left mt-4 mr-4 mb-4'>
                        <img src={images.Metegol} alt=""  />
                        <p className='italic font-inter text-[14px] leading-normal'>“Cuando alquilábamos armábamos el buffet en la camioneta de él (Alfredo) a las 6 AM, íbamos a la cancha el sábado, luego desarmábamos y el domingo la misma historia. Alfredo hacía los chori y yo cortaba entradas”</p>
                    </div>
                    Mientras Nino, incansable como sus alocados exabruptos, no ve tan clara la luz al final del túnel: “Hay gente que nos dice "ya están grandes, tienen que disfrutar"... pero si él no prendía el fuego, ¿quién lo hacía? ¿O si yo no cortaba las entradas? ¿Dónde está el disfrute?”. Y si bien esa complementación está argumentada en el tiempo juntos y los logros alcanzados, Menechella la explica también desde sus disímiles gustos personales: “¿Somos diferentes? Sí. A mi me gusta el escolazo, la joda y a éste no: no juega, no fuma. Esa es la única diferencia entre nosotros”. Aunque luego suma una tercera pata, la de la compañera de viaje: “Si éste no tuviera la mujer que tiene, todo hubiera sido imposible. Es una bestia cómo trabaja. Aparte nunca le rompió las bolas para nada, jamás. Yo me separé por esto. Si no tenés una mujer que te acompañe, no podés”.Para cerrar, el propio Nino deja un último pincelazo que recrea una idea vívi da de quiénes son este “par de pájaros” que soñó un “nido” para cobijar a cientos de pibes y hoy es realidad: “Nosotros somos inimputables... los dos. Siempre nos tomamos nuestro vinito pero no le hacemos mal a nadie. Vamos a San Martín y nos llaman 'vengan, vengan'... ¿Sabés qué pasa? Vamos sin prejuicios... a San Martín, a Lenci, a CRIBA o a cualquiera. Hay otros que van con el prejuicio y así seguro te va a ir mal”. Alfredo es el presidente de San Lorenzo de Villa Castells y Nino el Secretario del club... ¿Eso importa? Claro que no, a ellos sólo los mueve la pasión por el Cuervo y el camino hecho a la par con base en la amistad y, por supuesto, el amor propio.
                    </p>
                </div>
                <i className='text-left'>~ Noviembre, 2019</i>
            </article>
        </section>
    </MainLayout>
  )
}

export default AboutUsPage